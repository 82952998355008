<template>
	<div class="diy_edit page_reservation_information" id="reservation_information_edit">
		<div class='warp'>
			<div class='container'>
				<div class='row diy_edit_content_box'>
						<div v-if="$check_field('set','reservation_no') || $check_field('add','reservation_no') || $check_field('get','reservation_no')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								预定号:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_reservation_no" v-model="form['reservation_no']" placeholder="请输入预定号" v-if="(form['reservation_no'] && $check_field('set','reservation_no')) || (!form['reservation_no'] && $check_field('add','reservation_no'))" :disabled="true"/>
							<span v-else-if="$check_field('get','reservation_no')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','management_no') || $check_field('add','management_no') || $check_field('get','management_no')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								管理编号:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_management_no" v-model="form['management_no']" placeholder="请输入管理编号" v-if="(form['management_no'] && $check_field('set','management_no')) || (!form['management_no'] && $check_field('add','management_no'))"  :disabled="disabledObj['management_no_isDisabled']"/>
							<span v-else-if="$check_field('get','management_no')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','name_of_home_stay') || $check_field('add','name_of_home_stay') || $check_field('get','name_of_home_stay')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								民宿名称:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_name_of_home_stay" v-model="form['name_of_home_stay']" placeholder="请输入民宿名称" v-if="(form['name_of_home_stay'] && $check_field('set','name_of_home_stay')) || (!form['name_of_home_stay'] && $check_field('add','name_of_home_stay'))"  :disabled="disabledObj['name_of_home_stay_isDisabled']"/>
							<span v-else-if="$check_field('get','name_of_home_stay')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','room_type') || $check_field('add','room_type') || $check_field('get','room_type')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								房间类型:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_room_type" v-model="form['room_type']" placeholder="请输入房间类型" v-if="(form['room_type'] && $check_field('set','room_type')) || (!form['room_type'] && $check_field('add','room_type'))"  :disabled="disabledObj['room_type_isDisabled']"/>
							<span v-else-if="$check_field('get','room_type')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','home_stay_specifications') || $check_field('add','home_stay_specifications') || $check_field('get','home_stay_specifications')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								民宿规格:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_home_stay_specifications" v-model="form['home_stay_specifications']" placeholder="请输入民宿规格" v-if="(form['home_stay_specifications'] && $check_field('set','home_stay_specifications')) || (!form['home_stay_specifications'] && $check_field('add','home_stay_specifications'))"  :disabled="disabledObj['home_stay_specifications_isDisabled']"/>
							<span v-else-if="$check_field('get','home_stay_specifications')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','room_price') || $check_field('add','room_price') || $check_field('get','room_price')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								房间单价:
							</span>
						</div>
								<!-- 数字 -->
						<div class="diy_field diy_number">
							<input type="number" id="form_room_price" v-model.number="form['room_price']" placeholder="请输入房间单价" v-if="(form['room_price'] && $check_field('set','room_price')) || (!form['room_price'] && $check_field('add','room_price'))" :disabled="disabledObj['room_price_isDisabled']" />
							<span v-else-if="$check_field('get','room_price')">{{ form['${obj.name}'] }}</span>
						</div>
							</div>
							<div v-if="$check_field('set','merchant_users') || $check_field('add','merchant_users') || $check_field('get','merchant_users')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								商家用户:
							</span>
						</div>
						<div class="diy_field diy_down">
							<select id="form_merchant_users" :disabled="disabledObj['merchant_users_isDisabled']" v-model="form['merchant_users']" v-if="(form['merchant_users'] && $check_field('set','merchant_users')) || (!form['merchant_users'] && $check_field('add','merchant_users'))" >
								<option v-for="o in list_user_merchant_users" :value="o['user_id']">
									{{o['nickname'] + '-' + o['username']}}
								</option>
							</select>
							<span v-else-if="$check_field('get','merchant_users')">{{ form['merchant_users'] }}</span>
						</div>
					</div>
							<div v-if="$check_field('set','merchant_name') || $check_field('add','merchant_name') || $check_field('get','merchant_name')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								商家名称:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_merchant_name" v-model="form['merchant_name']" placeholder="请输入商家名称" v-if="(form['merchant_name'] && $check_field('set','merchant_name')) || (!form['merchant_name'] && $check_field('add','merchant_name'))"  :disabled="disabledObj['merchant_name_isDisabled']"/>
							<span v-else-if="$check_field('get','merchant_name')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','merchant_address') || $check_field('add','merchant_address') || $check_field('get','merchant_address')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								商家地址:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_merchant_address" v-model="form['merchant_address']" placeholder="请输入商家地址" v-if="(form['merchant_address'] && $check_field('set','merchant_address')) || (!form['merchant_address'] && $check_field('add','merchant_address'))"  :disabled="disabledObj['merchant_address_isDisabled']"/>
							<span v-else-if="$check_field('get','merchant_address')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','ordinary_users') || $check_field('add','ordinary_users') || $check_field('get','ordinary_users')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								普通用户:
							</span>
						</div>
						<div class="diy_field diy_down">
							<select id="form_ordinary_users" :disabled="disabledObj['ordinary_users_isDisabled']" v-model="form['ordinary_users']" v-if="(form['ordinary_users'] && $check_field('set','ordinary_users')) || (!form['ordinary_users'] && $check_field('add','ordinary_users'))" >
								<option v-for="o in list_user_ordinary_users" :value="o['user_id']">
									{{o['nickname'] + '-' + o['username']}}
								</option>
							</select>
							<span v-else-if="$check_field('get','ordinary_users')">{{ form['ordinary_users'] }}</span>
						</div>
					</div>
							<div v-if="$check_field('set','scheduled_quantity') || $check_field('add','scheduled_quantity') || $check_field('get','scheduled_quantity')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								预定数量:
							</span>
						</div>
								<!-- 数字 -->
						<div class="diy_field diy_number">
							<input type="number" id="form_scheduled_quantity" v-model.number="form['scheduled_quantity']" placeholder="请输入预定数量" v-if="(form['scheduled_quantity'] && $check_field('set','scheduled_quantity')) || (!form['scheduled_quantity'] && $check_field('add','scheduled_quantity'))" :disabled="disabledObj['scheduled_quantity_isDisabled']" />
							<span v-else-if="$check_field('get','scheduled_quantity')">{{ form['${obj.name}'] }}</span>
						</div>
							</div>
							<div v-if="$check_field('set','scheduled_days') || $check_field('add','scheduled_days') || $check_field('get','scheduled_days')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								预定天数:
							</span>
						</div>
								<!-- 数字 -->
						<div class="diy_field diy_number">
							<input type="number" id="form_scheduled_days" v-model.number="form['scheduled_days']" placeholder="请输入预定天数" v-if="(form['scheduled_days'] && $check_field('set','scheduled_days')) || (!form['scheduled_days'] && $check_field('add','scheduled_days'))" :disabled="disabledObj['scheduled_days_isDisabled']" />
							<span v-else-if="$check_field('get','scheduled_days')">{{ form['${obj.name}'] }}</span>
						</div>
							</div>
							<div v-if="$check_field('set','payment_amount') || $check_field('add','payment_amount') || $check_field('get','payment_amount')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								支付金额:
							</span>
						</div>
								<!-- 文本 -->
									<div class="diy_field diy_text">
							<input type="text" id="form_payment_amount" v-model="form['payment_amount']" placeholder="请输入支付金额" v-if="(form['payment_amount'] && $check_field('set','payment_amount')) || (!form['payment_amount'] && $check_field('add','payment_amount'))"  @focus="set_payment_amount()" :disabled="disabledObj['payment_amount_isDisabled']"/>
							<span v-else-if="$check_field('get','payment_amount')">{{ form['${obj.name}'] }}</span>
						</div>
										</div>
							<div v-if="$check_field('set','order_date') || $check_field('add','order_date') || $check_field('get','order_date')" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								下单日期:
							</span>
						</div>
								<!-- 日期 -->
						<div class="diy_field diy_date">
							<input type="date" :disabled="disabledObj['order_date_isDisabled']" id="form_order_date" v-model="form['order_date']" placeholder="请输入下单日期" v-if="(form['order_date'] && $check_field('set','order_date')) || (!form['order_date'] && $check_field('add','order_date'))" />
							<span v-else-if="$check_field('get','order_date')">{{ form['${obj.name}'] }}</span>
						</div>
							</div>



					<div v-if="user_group === '管理员' || $check_examine()" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								审核状态:
							</span>
						</div>
						<div class="diy_field diy_select" v-if="$check_action('/reservation_information/edit','examine')">
							<!--<span> {{ form['examine_state'] }} </span>-->
							<select v-model="form['examine_state']">
								<option value="未审核">
									未审核
								</option>
								<option value="已通过">
									已通过
								</option>
								<option value="未通过">
									未通过
								</option>
							</select>
						</div>
						<div class="diy_field diy_text" v-else>
							<span>
								{{ form['examine_state'] }}
							</span>
						</div>
					</div>
					<div v-if="user_group === '管理员' || $check_examine()" class="form-item col-12 col-md-6">
						<div class="diy_title">
							<span>
								审核回复:
							</span>
						</div>
						<div class="diy_field diy_desc" v-if="$check_action('/reservation_information/edit','examine')">
							<textarea v-model="form['examine_reply']"></textarea>
						</div>
						<div class="diy_field diy_text" v-else>
							<span>
								{{ form['examine_reply'] }}
							</span>
						</div>
					</div>


				</div>
				<div class="diy_edit_submit_box row">
					<div class="col-12">
						<div class="btn_box">
							<button class="btn_submit" @click="submit()">提交</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from "@/mixins/page.js";
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				url_get_obj: "~/api/reservation_information/get_obj?",
				url_add: "~/api/reservation_information/add?",
				url_set: "~/api/reservation_information/set?",

				// 登录权限
				oauth: {
					"signIn": true,
					"user_group": []
				},

				// 查询条件
				query: {
						"reservation_no": "",
							"management_no": "",
							"name_of_home_stay": "",
							"room_type": "",
							"home_stay_specifications": "",
							"room_price": 0,
							"merchant_users": 0,
							"merchant_name": "",
							"merchant_address": "",
							"ordinary_users": 0,
							"scheduled_quantity": 0,
							"scheduled_days": 0,
							"payment_amount": "",
							"order_date": "",
						"reservation_information_id": 0,
				},

				obj: {
						"reservation_no": this.$get_stamp(), // 预定号
							"management_no":  '', // 管理编号
							"name_of_home_stay":  '', // 民宿名称
							"room_type":  '', // 房间类型
							"home_stay_specifications":  '', // 民宿规格
							"room_price":  0, // 房间单价
							"merchant_users": 0, // 商家用户
							"merchant_name":  '', // 商家名称
							"merchant_address":  '', // 商家地址
							"ordinary_users": 0, // 普通用户
							"scheduled_quantity":  0, // 预定数量
							"scheduled_days":  0, // 预定天数
							"payment_amount":  '', // 支付金额
							"order_date": new Date().getTime(),
						"examine_state": "未审核",
					"examine_reply": "",
					"reservation_information_id": 0,
				},

				// 表单字段
				form: {
						"reservation_no": this.$get_stamp(), // 预定号
							"management_no":  '', // 管理编号
							"name_of_home_stay":  '', // 民宿名称
							"room_type":  '', // 房间类型
							"home_stay_specifications":  '', // 民宿规格
							"room_price":  0, // 房间单价
							"merchant_users": 0, // 商家用户
							"merchant_name":  '', // 商家名称
							"merchant_address":  '', // 商家地址
							"ordinary_users": 0, // 普通用户
							"scheduled_quantity":  0, // 预定数量
							"scheduled_days":  0, // 预定天数
							"payment_amount":  '', // 支付金额
							"order_date": new Date().getTime(),
						"examine_state": "未审核",
					"examine_reply": "",
					"reservation_information_id": 0,
				},
				disabledObj:{
						"reservation_no_isDisabled": false,
							"management_no_isDisabled": false,
							"name_of_home_stay_isDisabled": false,
							"room_type_isDisabled": false,
							"home_stay_specifications_isDisabled": false,
									"merchant_users_isDisabled": false,
							"merchant_name_isDisabled": false,
							"merchant_address_isDisabled": false,
							"ordinary_users_isDisabled": false,
											"payment_amount_isDisabled": false,
							"order_date_isDisabled": false,
					},

																		// 用户列表
				list_user_merchant_users: [],
											// 用户列表
				list_user_ordinary_users: [],

				// ID字段
				field: "reservation_information_id",

			}
		},
		methods: {






						/**
			 * 获取商家用户用户列表
			 */
			async get_list_user_merchant_users() {
				// if(this.user_group !== "管理员" && this.form["merchant_users"] === 0) {
				//     this.form["merchant_users"] = this.user.user_id;
				// }
				var json = await this.$get("~/api/user/get_list?user_group=商家用户");
				if(json.result && json.result.list){
					this.list_user_merchant_users = json.result.list;
				}
				else if(json.error){
					console.error(json.error);
				}
			},



						/**
			 * 获取普通用户用户列表
			 */
			async get_list_user_ordinary_users() {
				// if(this.user_group !== "管理员" && this.form["ordinary_users"] === 0) {
				//     this.form["ordinary_users"] = this.user.user_id;
				// }
				var json = await this.$get("~/api/user/get_list?user_group=普通用户");
				if(json.result && json.result.list){
					this.list_user_ordinary_users = json.result.list;
				}
				else if(json.error){
					console.error(json.error);
				}
			},
					async get_user_session_ordinary_users(){
				var _this = this;
				var json = await this.$get("~/api/user_group/get_obj?name=普通用户");
				if(json.result && json.result.obj){
					var source_table = json.result.obj.source_table;
					var user_id = _this.$store.state.user.user_id;
					if (user_id){
						var url = "~/api/"+source_table+"/get_obj?"
						this.$get(url, {"user_id":_this.$store.state.user.user_id}, function(res) {
							if (res.result && res.result.obj) {
								var arr = []
								for (let key in res.result.obj) {
									arr.push(key)
								}
								var arrForm = []
								for (let key in _this.form) {
									arrForm.push(key)
								}
								_this.form["ordinary_users"] = user_id
								_this.disabledObj['ordinary_users' + '_isDisabled'] = true
								for (var i=0;i<arr.length;i++){
                  if (arr[i]!=='examine_state' && arr[i]!=='examine_reply') {
                    for (var j = 0; j < arrForm.length; j++) {
                      if (arr[i] === arrForm[j]) {
                        if (arr[i] !== "ordinary_users") {
                          _this.form[arrForm[j]] = res.result.obj[arr[i]]
                          _this.disabledObj[arrForm[j] + '_isDisabled'] = true
                          break;
                        }
                      }
                    }
                  }
								}
							}
						});
					}
				}
				else if(json.error){
					console.error(json.error);
				}
			},




									set_payment_amount(){
				this.form.payment_amount = parseFloat(this.form.scheduled_days) * parseFloat(this.form.scheduled_quantity) * parseFloat(this.form.room_price)
        let r = /^\+?[1-9][0-9]*$/; // 正整数
        if(!r.test(this.form.payment_amount) ){
          this.form.payment_amount = this.form.payment_amount.toFixed(2);
        }
			},


			/**
			 * 修改文件
			 * @param { Object } files 上传文件对象
			 * @param { String } str 表单的属性名
			 */
			change_file(files, str) {
				var form = new FormData();
				form.append("file", files[0]);
				this.$post("~/api/reservation_information/upload?", form, (res) => {
					if (res.result) {
						this.form[str] = res.result.url;
					} else if (res.error) {
						this.$toast(res.error.message);
					}
				});
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_before(param){
				var form = $.db.get("form");
				// if (form) {
        //   delete(form.examine_state)
        //   delete(form.examine_reply)
        //   this.obj = $.push(this.obj ,form);
				// 	this.form = $.push(this.form ,form);
				// }
				// var arr = []
				// for (let key in form) {
				// 	arr.push(key)
				// }
				// for (var i=0;i<arr.length;i++){
				// 	this.disabledObj[arr[i] + '_isDisabled'] = true
				// }
        if (form) {
          var arr = []
          for (let key in form) {
            arr.push(key)
          }
          var arrForm = []
          for (let key in this.form) {
            arrForm.push(key)
          }
          for (var i=0;i<arr.length;i++){
            if (arr[i]!=='examine_state' && arr[i]!=='examine_reply') {
              for (var j = 0; j < arrForm.length; j++) {
                if (arrForm[j] === arr[i]) {
                  this.form[arrForm[j]] = form[arr[i]]
                  this.obj[arrForm[j]] = form[arr[i]]
                  this.disabledObj[arrForm[j] + '_isDisabled'] = true
                  break;
                }
              }
            }
          }
        }
																												        if (JSON.stringify(this.form["order_date"]).indexOf("-")===-1) {
          this.form["order_date"] = this.$toTime(parseInt(this.form["order_date"]), "yyyy-MM-dd")
        }

        $.db.del("form");
				return param;
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json ,func){
				// var form = $.db.get("form");
				// var obj = Object.assign({} ,form ,this.obj);
				// if (obj) {
        //   delete(obj.examine_state)
        //   delete(obj.examine_reply)
				// 	this.obj = $.push(this.obj ,obj);
				// }
				// if (form) {
        //   delete(form.examine_state)
        //   delete(form.examine_reply)
				// 	this.form = $.push(this.form ,form);
				// }
				if(func){
					func(json);
				}
			},

		},
		created() {
																								this.get_list_user_merchant_users();
												this.get_user_session_ordinary_users();
					this.get_list_user_ordinary_users();
															},
	}
</script>

<style>




</style>
